import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { PlanListWidgetRole } from '../../constants/elements';

export const getWidgetManifest: GetWidgetManifestFn = (builder) => {
  builder.set({ displayName: 'Plan List' });
  builder.behavior().set({ resizable: true, duplicatable: true });
  builder.configureConnectedComponents(PlanListWidgetRole.PlansInfoState, (containerBuilder) => {
    containerBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });
  builder.configureConnectedComponents(PlanListWidgetRole.PlanListStates, (statesBuilder) => {
    statesBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });
  builder.configureConnectedComponents(PlanListWidgetRole.Header, (headerBuilder) => {
    headerBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });
  builder.configureConnectedComponents(PlanListWidgetRole.PlanList, (planListBuilder) => {
    planListBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true }, preventHide: true });
  });
};
